.login-form {
  z-index: 999;
  &__bg-photo {
    background: url('../../assets/images/login-bg.jpg') center no-repeat;
    width: 100%;
    height: 100vh;
    background-size: cover;
  }
  &__container {
    width: 100%;
  }

  &__form-container {
    position: absolute;
    height: 320px;
    width: 300px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
    margin-top: -150px;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px $mainGrey;
  }

  &__logo {
    position: absolute;
    width: 70px;
    height: 70px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -200px;
    z-index: 1;
  }

  &__label {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
    color: $primaryBlue;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  &__password {
    display: flex;
    position: relative;
  }

  &__password-input {
    display: block;
    text-transform: none;
  }

  &__password-show {
    cursor: pointer;
    position: absolute;
    right: 0;
    font-size: 1.3rem;
    color: rgba(136, 143, 138, 0.8);
  }

  &__reset-div {
    display: flex;
    justify-content: center;
    margin: 5px 0;
    cursor: pointer;
  }

  &__reset-div:hover {
    color: rgb(0, 117, 250);
    text-decoration: underline;
  }
}
