.generic-content-container {
  h2 {
    text-transform: uppercase;
    font-weight: 300;
    margin: 10px 0;
  }
}

.content-container {
  display: flex;
  width: 100%;
  height: 300px;
  align-items: center;
  justify-content: center;
  position: relative;
}