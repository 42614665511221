.update-loadplan {
  border: 1px solid red;
  text-align: center;
  &__label {
    font-weight: 500;
    color: red;
    padding-right: 10px;
    padding-left: 10px;
  }
}
