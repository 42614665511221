.po-form-container {
  width: 100%;
  padding: 0 25px !important;
  transition: all 0.3s ease;
  &--active {
    // display: block;
    width: 100%;
    transition: all 0.3s ease;
    opacity: 1;
    transform: translateX(0%);
  }

  &--inactive {
    // display: none;
    transition: all 0.3s ease;
    height: 0;
    opacity: 0;
    transform: translateX(210%);
  }
}
