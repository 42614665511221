.mtracking {
  &--tg {
    border-collapse: collapse;
    border-spacing: 0;
  }
  &--tg-table {
    table-layout: fixed;
    width: 100%;
  }

  &--tg-td {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    overflow: hidden;
    padding: 10px 10px;
    word-break: normal;
  }
  &--tg-th {
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    padding: 10px 10px;
    word-break: normal;
  }
  &--tg-82ja {
    border-color: #c0c0c0;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    vertical-align: middle;
  }
  &--tg-4xtg {
    background-color: #c0c0c0;
    border-color: #c0c0c0;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    vertical-align: middle;
    padding: 10px 10px;
  }
  &--tg-ve35 {
    border-color: #c0c0c0;
    text-align: left;
    vertical-align: middle;
  }
  &--tg-xoqx {
    border-color: #c0c0c0;
    font-size: 11px;
    font-weight: bold;
    text-align: left;
    vertical-align: middle;
    padding: 10px 10px;
  }
  &--tg-lacr {
    background-color: #efefef;
    border-color: #c0c0c0;
    font-size: 11px;
    font-weight: bold;
    text-align: left;
    vertical-align: middle;
    padding: 10px 10px;
  }
  &--tg-4ydh {
    background-color: #efefef;
    border-color: #c0c0c0;
    text-align: left;
    vertical-align: middle;
  }
  &--tg-bzca {
    background-color: #efefef;
    border-color: #c0c0c0;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    vertical-align: middle;
  }
  &--span-desc {
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 500;
  }
}
