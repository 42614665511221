@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  color: #333;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
