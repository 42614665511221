.empty_table {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 100%;

  &__wrapper {
    text-align: center;
    flex-direction: column;
  }

  &__header {
    margin-bottom: 12px;
  }
}