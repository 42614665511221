.row {
  /* display: inline-block; */

  &__leftpanel {
    transition: all 0.3s ease;
    display: flex;
    margin: 0;
    width: 75px;

    &--isActive {
      transition: all 0.3s ease;
      width: 150px;
    }
  }

  &--f-col2 {
    display: flex;
    overflow-x: hidden;
  }
}
