.table {
  /* overflow: auto;
  table-layout: auto !important; */
  &--horiz-scroll {
    overflow-x: auto;
    table-layout: auto !important;
  }

  &--col-w {
    width: 500px;
  }

  &--col-w100 {
    width: 90% !important;
    max-width: 60vw !important;
  }
}
