.users-container {
  width: 100%;
  padding: 0 25px !important;

  &--active {
    // display: block;
    width: 100%;
    transition: all 0.3s ease;
    opacity: 1;
    transform: translateX(0%);
  }

  &--inactive {
    // display: none;
    transition: all 0.3s ease;
    height: 0;
    opacity: 0;
    transform: translateX(210%);
  }
}

.email-checkbox-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 2rem;

  input {
    cursor: pointer;
  }
}
