.form-group {
  &--w-30 {
    width: 30%;
  }
  &--w-50 {
    width: 50%;
  }
  &--w-100 {
    width: 100%;
  }
}
