.errorpage {
  background: url('../../assets/images/ErrorPage404.jpg') center no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  background-size: cover;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
