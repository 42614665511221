.faq {
  &__header-q {
    color: $mainBlue;
    font-size: 1.8rem;
  }

  &__header-a {
    color: $mainGrey;
    font-size: 1.8rem;
  }

  &__header-question {
    font-weight: 400;
    font-size: 1.2rem;
    color: $mainBlue;
  }

  &__header-answer {
    color: $mainGrey;
    font-weight: 400;
    font-size: 1.2rem;
  }

  &__title {
    font-size: 2.2rem;
    font-weight: bolder;
  }
}
