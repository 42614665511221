.wrapper {
  overflow: hidden;
  width: 100%;
  max-width: 95vw;
  /* max-width: 1236px; */
  /*   padding-left: 18px;
  padding-right: 18px; */
  /* margin-left: auto;
  margin-right: auto; */
  &__fix-table {
    max-width: 100%;
    max-height: 90vh;
    overflow-y: scroll;
  }
}