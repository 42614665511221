.sidebar {
  &__not-active {
    display: none;
  }
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: $mainBlue;
  overflow: hidden;
  width: 75px;
  transition: all 0.3s ease;

  &--active {
    width: 150px;
  }

  &--menubar {
    display: flex;
    height: 75px;
    width: 100%;
    color: #fff;
    font-size: 20px;
    border-bottom: 2px solid #c0c0c0;
    &-icon {
      cursor: pointer;
      margin: auto 0;
    }

    &-active {
      transition: all 0.3s ease;
      padding-left: 10px;
    }
    &-in-active {
      transition: all 0.3s ease;
      padding-left: 25px;
    }
  }

  &--menubar-title {
    margin: auto 0;
    padding-left: 8px;
    font-size: 1.5rem;
    font-weight: 700;
    &--inactive {
      display: none;
    }
  }

  .main-menu {
    text-decoration: none;
    width: 200px;
    color: #fff;
    overflow: hidden;
    list-style-type: none;

    .sub-menu {
      box-sizing: border-box;
      border-left: 1px solid #c0c0c0;
      margin-left: 20px;
      list-style-type: none;
      &--active {
        list-style-type: none;
        width: 100%;
        display: block;
        //transition: all 0.2s ease;
        //opacity: 1;
        //transform: translateX(0%);
      }

      &--inactive {
        display: none;
        //list-style-type: none;
        //transition: all 0.2s ease;
        //height: 0;
        //opacity: 0;
        //transform: translateX(-200%);
      }

      a {
        color: #fff;
        display: block;
        text-decoration: none;
        padding: 2px 20px;
        font-size: 0.8rem;
        font-weight: 100;
        list-style-type: none;
      }

      a:hover {
        background-color: rgba(0,0,0,0)//$primaryBlue;
      }
    }

    .menu-item {
      text-decoration: none;
      cursor: pointer;
      display: block;
      line-height: 40px;
      padding-left: 10px;
      list-style-type: none;
      &--title {
        color: #fff;
        font-weight: 100;
        font-size: 0.9rem;
        padding-left: 10px;
        transition: all 0.3s ease;

        &__active {
          opacity: 1;
        }

        &__inactive {
          opacity: 0;
        }
      }

      .menu-icon {
        display: inline-block;
        color: #fff;
        transition: all 0.3s ease;
        &--inactive {
          padding-left: 15px;
        }
      }
    }

    .menu-item:hover {
      background-color: rgba(0,0,0,0);//$primaryBlue;
    }
  }

  .bottom-container {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
    // height: 130px;

    .logo-container {
      object-fit: contain;
      width: 40px;
      height: 40px;
      transition: all 0.3s ease;
      &--inactive {
        width: 30px;
        height: 30px;
      }
    }

    .name-container {
      color: #fff;
      padding: 4px 0;
      font-weight: 200;
      overflow: hidden;
      max-width: 10ch;
      text-overflow: ellipsis;
      white-space: nowrap;
      &--inactive {
        font-size: 0.8rem;
        max-width: 5ch;
      }
    }
    .clock-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      width: 100%;
    }
  }
}
