.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg--header {
  table-layout: fixed;
  width: 100%;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg .tg-v4j2 {
  background-color: #c0c0c0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
