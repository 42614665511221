.side-nav {
  &__not-active {
    display: none;
  }

  height: 100vh;
  width: 150px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: $mainBlue;
  overflow: hidden;
  padding-top: 20px;

  &--is-collapse {
    width: 65px;

    i {
      padding: 0;
    }
  }

  &__photo {
    width: 80px;
    height: 80px;
    margin: auto;
    margin-top: 7px;
  }

  &__title {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 700;
    margin: 15px auto;
    text-align: center;
  }

  &__links {
    padding: 6px 5px 6px 5px;
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: 300;
    text-align: left;
    color: #fff;
    display: block;
    width: 100%;
    cursor: pointer;
    outline: none;
    transition: 200ms ease;
  }

  &--is-collapse__links {
    padding: 6px 0;
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: 300;
    text-align: left;
    color: #fff;
    display: block;
    width: 100%;
    cursor: pointer;
    outline: none;
  }

  &__links:hover,
  &--is-collapse__links:hover {
    color: #fff;
    background-color: $primaryBlue;
    text-decoration: none;
  }

  .dropdown-btn {
    padding: 6px 5px 6px 5px;
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: 300;
    text-align: left;
    color: #fff;
    display: block;
    width: 100%;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;

    &--active {
      padding: 6px 0;
      text-decoration: none;
      font-size: 0.8rem;
      font-weight: 300;
      text-align: left;
      color: #fff;
      display: block;
      width: 100%;
      background: none;
      border: none;
      cursor: pointer;
      outline: none;
    }

    &--active:hover {
      background-color: $primaryBlue;
    }
  }

  .dropdown-btn:hover {
    background-color: $primaryBlue;
    color: #fff;
  }

  i {
    padding: 0 10px;
  }

  &--dropdown-container-loadplan-hide {
    //display: none;
    transition: all 0.3s ease;
    height: 0;
    opacity: 0;
    transform: translateX(-110%);
  }

  &--dropdown-container-loadplan-visible {
    //display: block;
    padding-left: 35px;
    background-color: $altBlue;
    width: 100%;
    transition: all 0.3s ease;
    opacity: 1;
    transform: translateX(0%);
    height: 9.8rem;
  }

  &--dropdown-container-reports-hide {
    //display: none;
    transition: all 0.3s ease;
    height: 0;
    opacity: 0;
    transform: translateX(-110%);
  }

  &--dropdown-container-reports-visible {
    //display: block;
    padding-left: 35px;
    background-color: $altBlue;
    width: 100%;
    transition: all 0.3s ease;
    opacity: 1;
    transform: translateX(0%);
    height: 11.8rem;
  }

  &--dropdown-container-tracking-hide {
    //display: none;
    transition: all 0.3s ease;
    height: 0;
    opacity: 0;
    transform: translateX(-110%);
  }

  &--dropdown-container-tracking-visible {
    //display: block;
    padding-left: 35px;
    background-color: $altBlue;
    width: 100%;
    transition: all 0.3s ease;
    opacity: 1;
    transform: translateX(0%);
    height: 7.9rem;
  }

  &__caret-right {
    float: right;
  }

  /* Collapse Toggle */

  &--hide-title {
    display: none;
  }

  &--username {
    position: absolute;
    bottom: 0;
    padding: 6px 5px 6px 15px;
    color: #ffff;
    font-size: 0.8rem;
    font-weight: 200;
  }
}
